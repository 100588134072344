<template>
  <div class="modal fade " id="createOrUpdateTextTemplate" tabindex="-1" role="dialog"
       aria-labelledby="createOrUpdateTextTemplate"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-md">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">{{ modalTitle }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Mode <span class="text-danger">*</span></label>
                  <div class="checkbox-container" style="display: flex; gap: 5px;">
                    <div class="custom-control-inline custom-control custom-radio">
                      <input v-model="mode" type="radio" class="custom-control-input bg-danger"
                             id="appointmentMode" name="mode" value="1">
                      <label for="appointmentMode" class="custom-control-label font-weight-bold">Appointment</label>
                    </div>

                    <div class="custom-control-inline custom-control custom-radio">
                      <input v-model="mode" type="radio" class="custom-control-input bg-warning"
                             id="orderMode" name="mode" value="2">
                      <label for="orderMode" class="custom-control-label font-weight-bold">Order</label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="templateType">Template Type <span class="text-danger">*</span></label>
                  <VueMultiselect v-model="selectedTemplateType" :allow-empty="false"
                                  :options="templateTypeList"
                                  :close-on-select="true" placeholder="Select type" label="name" track-by="value"
                                  id="templateType"
                                  :show-labels="false"/>

                </div>
                <div v-if="mode == 1 && selectedTemplateType.value == 3" class="form-group">
                  <label for="serviceType">Service List</label>
                  <VueMultiselect v-model="selectedService" :allow-empty="false" :options="serviceOptions"
                                  :close-on-select="true" placeholder="Select type" label="name" track-by="value"
                                  id="serviceType"
                                  :show-labels="false"/>

                </div>
                <div v-if="toggleStatusNote" class="form-group">
                  <label for="titleSelectType">Status Type</label>
                  <VueMultiselect v-model="selectedStatusNoteType" :allow-empty="false" :options="statusNoteOptions"
                                  :close-on-select="true" placeholder="Select type" label="name" track-by="value"
                                  id="titleSelectType"
                                  :show-labels="false"/>
                </div>
                <div class="form-group">
                  <label for="title">Title <span class="text-danger">*</span></label>
                  <input v-model="postTextTemplateData.title" type="text" class="form-control" id="title">
                </div>
                <div class="form-group">
                  <label for="description">Template <span class="text-danger">*</span></label>
                  <textarea v-model="postTextTemplateData.template" class="form-control" id="description"
                            rows="3"></textarea>
                </div>
                <div class="form-group">
                  <div class="controls">
                    <label>Status</label>
                    <div style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="templateStatus" type="checkbox" class="custom-control-input" checked
                                 id="notifyToPoolTechnician">
                          <label class="custom-control-label mr-1" for="notifyToPoolTechnician"></label>
                        </div>
                        <span class="font-medium-1">{{ templateStatus ? "Yes" : "No" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button @click="saveTextTemplate" type="button" class="btn btn-small btn-danger">
                    <span class="">Save</span>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 py-1">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "shortTextTemplateCreate",
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      modalTitle: 'Create text template',
      mode: '1',
      templateStatus: true,
      selectedTemplateType: {
        value: '',
        name: 'Any'
      },
      selectedService: {
        value: '',
        name: 'Any'
      },
      selectedStatusNoteType: {
        value: '',
        name: 'Any'
      },
      templateType: [
        {
          value: '',
          name: 'Any'
        },
        {
          name: "New Appointment",
          value: 1,
        },
        {
          name: "New Order",
          value: 2,
        },
        {
          name: "Service Agreement",
          value: 3,
        },
        {
          name: "For Status",
          value: 4,
        },
        {
          name: "For Note",
          value: 5,

        },
        {
          name: "For Follow-up",
          value: 6,

        }
      ],
      getServicesParam: {
        status: 1
      },
      templateTypeList: [],
      statusNoteOptions: [],
      toggleStatusNote: false,
      postTextTemplateData: {
        mode: '',
        template_type: '',
        service_id: null,
        status_type: null,
        title: '',
        template: '',
        created_by: '',
        panel: 2, // 2: technician mob-app
        status: 1

      }
    }
  },
  computed: {

    serviceOptions() {
      return [{
        value: null,
        name: "Any",
      },
        ...this.$store.getters["appService/services"].map((item) => ({value: item.id, name: item.name})),
      ];
    },
    appointmentStatusList() {
      return [{
        value: null,
        name: "Any",
      },
        ...this.$store.getters["appSettings/settingAppointmentStatus"]?.value,
      ];
    },
    orderStatusList() {

      return [{
        value: null,
        name: "Any",
      },
        ...this.$store.getters["appSettings/settingOrderStatus"]?.value,
      ];
    },
    appointmentNoteTypeList() {
      return [{
        value: null,
        name: "Any",
      },
        ...this.$store.getters["appSettings/settingAppointmentNoteType"]?.value,
      ];
    },
    orderNoteTypeList() {
      return [{
        value: null,
        name: "Any",
      },
        ...this.$store.getters["appSettings/settingOrderNoteType"]?.value,
      ];
    },
  },
  watch: {
    mode: {
      handler: function (value) {
        this.selectedTemplateType = this.selectedService = this.selectedStatusNoteType = {
          value: '',
          name: 'Any'
        };
        const validAppointmentTemplateId = [1, 3, 4, 5, 6];
        const validOrderTemplateId = [2, 4, 5];
        if (value == 1) {
          this.templateTypeList = this.templateType.filter((item) => validAppointmentTemplateId.includes(Number(item.value)));
        }
        if (value == 2) {
          this.templateTypeList = this.templateType.filter((item) => validOrderTemplateId.includes(Number(item.value)));
        }
        this.postTextTemplateData.mode = value;
      },
      immediate: true,
    },
    selectedTemplateType: {
      handler: function (currentValue) {
        if (currentValue.value == 4 || currentValue.value == 5) {
          this.toggleStatusNote = true;
          this.statusNoteOptions = this.getStatusNoteOptions(this.mode, currentValue.value);
        } else {
          this.toggleStatusNote = false;
          this.statusNoteOptions = [];
        }
        this.postTextTemplateData.template_type = currentValue.value;
      }
    },
    selectedService: {
      handler: function (currentValue) {
        this.postTextTemplateData.service_id = currentValue.value;
      }
    },
    selectedStatusNoteType: {
      handler: function (currentValue) {
        this.postTextTemplateData.status_type = currentValue.value;
      }
    },
    templateStatus: {
      handler: function (currentValue) {
        this.postTextTemplateData.status = currentValue === true ? 1 : 0;
      }
    }
  },
  methods: {
    ...mapActions({
      getServices: "appService/getServices",
      postShortTextTemplateOnList: "appShortTextTemplates/postShortTextTemplateOnList",
    }),
    getStatusNoteOptions(mode, templateValue) {
      switch (mode) {
        case '1':
          return (templateValue == 4) ? this.appointmentStatusList :
              (templateValue == 5) ? this.appointmentNoteTypeList :
                  [];
        case '2':
          return (templateValue == 4) ? this.orderStatusList :
              (templateValue == 5) ? this.orderNoteTypeList :
                  [];
        default:
          return [];
      }
    },
    async saveTextTemplate() {
      this.postTextTemplateData.created_by = this.$store.getters["appAuthLogin/authUser"].id;
      this.loader(true);
      await this.postShortTextTemplateOnList(this.postTextTemplateData).then(async (response) => {
        this.loader(false);
        if (response.status === 201) {
          this.showToastMessage({
            message: 'Text template created successful.',
            type: 'success'
          });
          document.querySelector(`[data-target="#createOrUpdateTextTemplate"]`).click(); // modal close
        }

        if (response.message) {
          this.showToastMessage(response);
        }

      });

    }
  },
  async mounted() {
    await this.getServices(this.getServicesParam);
  }
}
</script>

<style scoped>

</style>