<script>
import CouponStatusUpdateAlertModal from "@/components/backEnd/modal/UpdateAlertModal.vue";
import CouponEditModal from "@/views/backEnd/coupons/includes/CouponEditModal.vue";
import AssignCouponModal from "@/views/backEnd/Marketing/Coupon/includes/AssignCouponModal.vue";
import CouponDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal.vue";
import ListPagination from "@/components/backEnd/pagination/ListPagination.vue";
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
import CreateOrUpdateTextTemplate from "@/views/backEnd/shortTextTemplate/includes/shortTextTemplateCreate.vue"
import {mapActions, mapGetters} from "vuex";


export default {
  name: "shortTextTemplateList",
  components: {
    AppLayout,
    ListPagination,
    CouponDeleteAlertModal,
    AssignCouponModal,
    CouponEditModal,
    CouponStatusUpdateAlertModal,
    CreateOrUpdateTextTemplate
  },
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',

      getShortTextTemplatesParams: {
        title: '',
        mode: '',
        template_type: '',
        status: '',
        with_relation: ['createdByUser'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },


      selectedPagination: {
        value: '',
        name: 'Default'
      },

      selectedType: {
        value: '',
        name: 'Any'
      },

      selectedTemplateType: {
        value: '',
        name: 'Any'
      },

      selectedStatus: {
        value: '',
        name: 'Any'
      },

      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },
      selectedShortTextTemplate: {},
      selectedShortTextTemplateId: '',
      modelIdForDelete: '',

      getSettingsParams: {
        type: ["default", "appointment", "order"],
        key: [
          "pagination",
          "order_status",
          "appointment_note_type",
          "appointment_status",
          "order_note_type",
        ],
      },

    }

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getShortTextTemplatesParams.pagination = pagination.value;
    },
    selectedType() {
      this.getShortTextTemplatesParams.mode = this.selectedType.value;
    },
    selectedTemplateType() {
      this.getShortTextTemplatesParams.template_type = this.selectedTemplateType.value;
    },
    selectedStatus() {
      this.getShortTextTemplatesParams.status = this.selectedStatus.value;
    }

  },
  computed: {
    ...mapGetters({
      paginateLinks: 'appShortTextTemplates/paginateLinks',
      shortTextTemplates: 'appShortTextTemplates/shortTextTemplates',
      services: 'appService/services',
    }),
    paginationOptions() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },

    typeOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 1,
          name: 'Appointment'
        },
        {
          value: 2,
          name: 'Order'
        }
      ];
    },

    templateTypeOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 1,
          name: 'New Appointment'
        },
        {
          value: 2,
          name: 'New Order'
        },
        {
          value: 3,
          name: 'Service Agreement'
        },
        {
          value: 4,
          name: 'For Status'
        },
        {
          value: 5,
          name: 'For Follow-up'
        },
      ];
    },

    statusOptions() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getServices: 'appService/getServices',
      getShortTextTemplates: 'appShortTextTemplates/getShortTextTemplates',
      getShortTextTemplate: 'appShortTextTemplates/getShortTextTemplate',
      putShortTextTemplateOnList: 'appShortTextTemplates/putShortTextTemplateOnList',
      deleteShortTextTemplateOnList: 'appShortTextTemplates/deleteShortTextTemplateOnList',
    }),

    async couponStatusUpdating(couponStatusUpdating) {
      await this.couponStatusUpdatingOnList(couponStatusUpdating);
    },

    async couponStatusUpdatingOnList(couponStatusUpdating) {
      let dataObj = {
        id: couponStatusUpdating.modelForUpdating.modelId,
        data: {
          status: couponStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }
      this.putCouponOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Coupon status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async assignCustomerCoupon(couponId) {
      this.selectedShortTextTemplateId = couponId;
      console.log(couponId)
    },

    async deleteSingleCouponOnLIst(id) {
      this.deleteCouponOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Coupon deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleCouponOnLIst(confirmModelDeletion.modelId);
    },

    async getShortTextTemplatesList() {
      await this.getShortTextTemplates(this.getShortTextTemplatesParams).then((response) => {
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async applyShortTextTemplateFilter(pageNumber) {
      await this.loader(true);
      this.getShortTextTemplatesParams.page = pageNumber;
      await this.getShortTextTemplatesList();
      await this.loader(false);
    },
    async openTextTemplateModal() {
      document.querySelector('[data-target="#createOrUpdateTextTemplate"]').click();
    },

    async getServiceList() {
      await this.getServices();
    },
    getServiceNameFromId(id) {
      if (id) {
        const matchedObject = this.services.find(item => item.id === parseInt(id));
        return matchedObject ? matchedObject.name : '';
      }
      return '';

    }
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getServiceList();
    await this.getShortTextTemplatesList();
    await this.loader(false);
  },
}
</script>

<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Short Text Template/List</h4>
          <div>
            <button class="btn btn-primary" @click="openTextTemplateModal">
              <span :title="can('short-text-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </button>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-3">
                <label>Show Per Page</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedPagination" class="" :options="paginationOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <label>Type</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedType" class="" :options="typeOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <label>Template Type</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedTemplateType" class=""
                                :options="templateTypeOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <label>Status</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="" :options="statusOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-10">
                <label>Title/Template</label>
                <input v-model="getShortTextTemplatesParams.name_code_query"
                       class="form-control search-product-input-element"
                       type="text" placeholder="Search by title or template">
              </div>
              <div class="col-12 col-sm-6 col-md-2 pt-2">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyShortTextTemplateFilter(null)">Filter
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'25%'}">TYPE,TEMPLATE TYPE</th>
                    <th class="position-relative" :style="{width:'25%'}">SERVICE, STATUS/NOTE TYPE</th>
                    <th class="position-relative" :style="{width:'10%'}">TITLE</th>
                    <th class="position-relative" :style="{width:'20%'}">TEMPLATE</th>
                    <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                    <th :style="{width:'5%'}">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(shortText, index) in shortTextTemplates" :key="index"
                      :class="index % 2 == 0? 'expire-date-bg' : ''">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span><strong class="text-uppercase">Type: </strong>{{ shortText.mode_text }}</span><br>
                      <span><strong class="text-uppercase">Template: </strong>{{
                          shortText.template_type_text
                        }}</span><br>
                    </td>
                    <td>
                      <span v-if="shortText.service_id || shortText.status_type">
                        <span v-if="shortText.service_id"><strong class="text-uppercase">Service: </strong>{{
                            shortText.service_id ? getServiceNameFromId(shortText.service_id) : 'Not provided'
                          }}</span><br>
                        <span v-if="shortText.status_type">
                          <strong class="text-uppercase">Status/Note Type: </strong>{{
                            shortText.status_type ?? 'Not provided'
                          }}</span>
                        <br>
                      </span><span v-else>Not provided</span>
                    </td>
                    <td>
                      <span>{{ shortText.title }}</span><br>
                    </td>
                    <td>
                      <span>{{ shortText.template }}</span><br>
                    </td>
                    <td>
                      <button class="btn"
                              :disabled="!can('short-text-template-update')"
                              :title="can('short-text-update-update') ? `${shortText.status} | Click to update` : `${shortText.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#shortTextStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: shortText.id, existingData: {status: shortText.status}}">
                        <span class="badge"
                              :class="shortText.status === 1 ? 'badge-light-success' : 'badge-light-danger'">
                          {{ shortText.status_text }}
                        </span>
                      </button>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <button
                              class="m-0 p-0 bg-transparent text-decoration-none cursor-pointer border-0 view-edit-delete-icon"
                              :disabled="!can('short-text-template-update')"
                              :title="can('short-text-template-update')? 'Update' : 'Update forbidden'"
                              :style="{height:'18px'}"
                              data-toggle="modal"
                              data-target="#shortTextEditModal"
                              @click="selectedShortTextTemplate = {}; selectedShortTextTemplate = shortText">
                            <i class='bx bx-edit'></i>
                          </button>
                        </div>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">

                            <button v-if="shortText.type ==='Assigned Only'" class="dropdown-item"
                                    :title="can('shortText-view') ? 'View' : 'View forbidden'"
                                    data-toggle="modal" data-target="#assignCouponModal"
                                    @click="assignCustomerCoupon(shortText.id)">Assign Customer
                            </button>
                            <button class="dropdown-item"
                                    :disabled="!can('shortText-delete')"
                                    :title="can('shortText-delete') ? 'Delete' : 'Delete forbidden'"
                                    data-toggle="modal" data-target="#shortTextDeleteAlertModal"
                                    @click="this.modelIdForDelete = shortText.id">Delete
                            </button>

                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ListPagination position="right" :pagination-data="paginateLinks"
                              @getClickedPage="applyShortTextTemplateFilter"/>
            </div>
          </div>
        </div>
        <create-or-update-text-template></create-or-update-text-template>

        <CouponStatusUpdateAlertModal :model-for-updating="modelForUpdating" model-name="Coupon"
                                      modal-name="couponStatusUpdateAlertModal"
                                      @confirmModelUpdating="couponStatusUpdating"/>
        <CouponEditModal :coupon="selectedShortTextTemplate"/>
        <AssignCouponModal :coupon-id="selectedShortTextTemplateId"/>
        <coupon-delete-alert-modal :model-id="modelIdForDelete" model-name="coupon" modal-name="couponDeleteAlertModal"
                                   @confirmModelDeletion="onConfirmModelDeletion"/>

        <div class="" data-toggle="modal" data-target="#createOrUpdateTextTemplate"></div>
      </section>
    </template>
  </AppLayout>
</template>

<style scoped>

</style>